import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade, Navigation, Pagination } from "swiper/modules";

import "./Banner.css";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Link, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { ThemeContext } from "../../Contexts/ThemeContext";
// import lgBanner1 from "../../Images/clariss-Perfume-slider-desktop.png";
import lgBanner2 from "../../Images/shampoo-web banner.png";
import lgBanner3 from "../../Images/PerfumeBanner.jpg";
// import mbBanner1 from "../../Images/clariss-Perfume-slider-Mobile.png";
import mbBanner2 from "../../Images/shampoo-web banner mobile_.png";
import mbBanner3 from "../../Images/PerfumeBanner-mb.png";

const Banner = () => {
  const { searchText, setSearchText } = useContext(ThemeContext);
  const isDesktop = window.innerWidth > 992;

  // const banner01 = window.innerWidth > 992 ? lgBanner1 : mbBanner1;
  const banner02 = window.innerWidth > 992 ? lgBanner2 : mbBanner2;
  const banner03 = window.innerWidth > 992 ? lgBanner3 : mbBanner3;

  const navigate = useNavigate();
  return (
    <div>
      <Swiper
        spaceBetween={30}
        effect={"fade"}
        navigation={window.innerWidth < 992 ? false : true}
        autoplay={{
          delay: 10000,
          disableOnInteraction: false,
        }}
        pagination={window.innerWidth < 700 ? false : { dynamicBullets: true }}
        modules={[Autoplay, EffectFade, Navigation, Pagination]}
        className="mySwiper h-auto"
      >
        <SwiperSlide>
          <Link to="/product-category/shampoo">
            <img
              className="w-full h-auto z-10"
              src={banner02}
              alt=""
              width={isDesktop ? 1920 : 400}
              height={isDesktop ? 500 : 200}
            />
          </Link>
        </SwiperSlide>
        <SwiperSlide>
          <Link to="/product-category/fragrances">
            <img
              className="w-full h-auto z-10"
              src={banner03}
              alt=""
              width={isDesktop ? 1920 : 400}
              height={isDesktop ? 500 : 200}
            />
          </Link>
        </SwiperSlide>
        {/* <SwiperSlide>
          <Link to="/product-category/face wash">
            <img
              className="w-full h-auto z-10"
              src="https://i.pinimg.com/736x/31/64/0c/31640c9e82b960bea86814a717ffcbf8.jpg"
              alt=""
              width={isDesktop ? 1920 : 400}
              height={isDesktop ? 500 : 200}
            />
          </Link>
        </SwiperSlide> */}
        {/* <SwiperSlide>
            <Link to="/product-category/fragrance">
              <img
                className="w-full h-auto z-10"
                src={banner01}
                alt=""
                width={isDesktop ? 1920 : 400}
                height={isDesktop ? 500 : 200}
              />
            </Link>
          </SwiperSlide> */}
      </Swiper>
    </div>
  );
};

export default Banner;
